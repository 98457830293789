import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './pedidoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';
import Aguardando from '../common/template/aguardando';

import { 
	setModoTela, initForm, salvar, excluir, getLista, 
	getListaDistribuidora, getListaPoliticaPreco, getListaUnidade, getListaMotorista, 
	getListaVeiculo, getListaCliente, getListaEndereco, getListaProduto, getListaCustoPrazo
} from './pedidoActions';
import DateFormat from '../common/dateFormat/DateFormat';

class PedidoAprovacao extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaDistribuidora();
		this.props.getListaPoliticaPreco();
		this.props.getListaUnidade();
		this.props.getListaMotorista();
		this.props.getListaVeiculo();
		this.props.getListaCliente();
		this.props.getListaEndereco();
		this.props.getListaProduto();
		this.props.getListaCustoPrazo();

		let idInterval = setInterval(() => {
			
			if (this.props.modoTela == 'lista') {
				this.props.getLista();
			}
			
			this.props.getListaDistribuidora();
			this.props.getListaPoliticaPreco();
			this.props.getListaUnidade();
			this.props.getListaMotorista();
			this.props.getListaVeiculo();
			this.props.getListaCliente();
			this.props.getListaEndereco();
			this.props.getListaProduto();
			this.props.getListaCustoPrazo();
		}, 60000);

		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={this.props.salvar} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data</Th>
								<Th alignCenter>Status</Th>
								<Th alignCenter>Giro</Th>
								<Th alignCenter>Motorista</Th>
								<Th alignCenter>Placa</Th>
								<Th alignCenter>Vendedor</Th>
								<Th alignCenter>Clientes</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataSqlParaTela(item.data_pedido)} ${item.nome_status}
										${item.giro} ${item.nome_motorista} ${item.placa_veiculo} ${item.nome_usuario_inclusao} ${item.clientes || ''}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).filter(item => item.id_status == 2 /* Aguardando Aprovação */).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataSqlParaTela(item.data_pedido)}</Td>
									<Td alignCenter>{item.nome_status}</Td>
									<Td alignCenter>{item.giro}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_usuario_inclusao}</Td>
									<Td alignCenter>{item.clientes}</Td>
									<Td alignRight minWidth={150}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
												this.props.initForm({
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
											}} />
											
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
												this.props.initForm({
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
												this.props.initForm({
													...item,
													itens: (item.itens || []).map(pedidoItem => ({
														...pedidoItem,
														quantidade: FormatUtils.formatarValorTela(pedidoItem.quantidade, 2),
														preco_unitario_bruto: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_bruto, 4),
														preco_unitario_base: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_base, 4),
														preco_unitario_frete: FormatUtils.formatarValorTela(pedidoItem.preco_unitario_frete, 4),
														preco_unitario: FormatUtils.formatarValorTela(pedidoItem.preco_unitario, 4),
														preco_prazo: FormatUtils.formatarValorTela(pedidoItem.preco_prazo, 4),
														valor_total: FormatUtils.formatarValorTela(pedidoItem.valor_total, 2),
														data_emissao_nfe: DateFormat.formatarDataSqlParaTela(pedidoItem.data_emissao_nfe)
													}))
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.pedido.modoTela,
	aguardando: state.pedido.aguardando,
	lista: state.pedido.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setModoTela, initForm, salvar, excluir, getLista, 
	getListaDistribuidora, getListaPoliticaPreco, getListaUnidade, getListaMotorista, 
	getListaVeiculo, getListaCliente, getListaEndereco, getListaProduto, getListaCustoPrazo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PedidoAprovacao);
