import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize, registerField } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('metaManutencaoConfiguracaoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/metaManutencaoConfiguracao`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_LISTADO',
        payload: request
    };
}

export function getListaMetaApuracao() {
    const request = axios.get(`${consts.API_URL}/metaManutencaoConfiguracao/apuracao`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_APURACAO_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {

		if (!registro.id) {
	        axios.post(`${consts.API_URL}/metaManutencaoConfiguracao`, {
                ...registro,
				percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_1, 2),
				percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_2, 2),
				custo_frota_ano_anterior: FormatUtils.formatarValorBanco(registro.custo_frota_ano_anterior, 2),
				media_produtividade_km: FormatUtils.formatarValorBanco(registro.media_produtividade_km, 0),
				percentual_feedback_motorista: FormatUtils.formatarValorBanco(registro.percentual_feedback_motorista, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/metaManutencaoConfiguracao`, {
                ...registro,
				percentual_reducao_custo_frota_faixa_1: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_1, 2),
				percentual_reducao_custo_frota_faixa_2: FormatUtils.formatarValorBanco(registro.percentual_reducao_custo_frota_faixa_2, 2),
				custo_frota_ano_anterior: FormatUtils.formatarValorBanco(registro.custo_frota_ano_anterior, 2),
				media_produtividade_km: FormatUtils.formatarValorBanco(registro.media_produtividade_km, 0),
				percentual_feedback_motorista: FormatUtils.formatarValorBanco(registro.percentual_feedback_motorista, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/metaManutencaoConfiguracao?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaUsuario() {
    const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_USUARIO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVeiculo() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarSelect`);
    return {
        type: 'META_MANUTENCAO_CONFIGURACAO_VEICULO_SELECT_LISTADO',
        payload: request
    };
}

export function importarCustos(tabela) {
    return async (dispatch, getState) => {

		dispatch(setAguardando(true));

		let lista = [];
		
		for (var i = 0; i < tabela.length; i++) {
			let item = tabela[i];

			let data = item[8];
			data.setHours(data.getHours() + 12);

			let valor = item[19];
			if (data && valor) {
				let competencia = `${data.getFullYear()}${String(data.getMonth() + 1).padStart(2, '0')}`;

				if (!lista.filter(registro => registro.competencia == competencia).length) {
					lista.push({
						competencia: competencia,
						valor: 0,
						id_usuario_inclusao: getState().auth.usuario.id,
						id_usuario_alteracao: getState().auth.usuario.id
					});
				}

				lista = lista.map(registro => ({
					...registro,
					valor: registro.valor + (registro.competencia == competencia ? valor : 0)
				}));

			}
		}

		console.log(lista);

		await axios.post(`${consts.API_URL}/custoFrotaMes`, {
			lista: lista
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaMetaApuracao());
		}).catch(e => {
			setErroAPI(e);
		});

		dispatch(setAguardando(false));

	}
}