import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import { setError } from '../common/toast/toast';
import readXlsxFile from 'read-excel-file/web-worker'
import ReactExport from 'react-export-excel-fixed-xlsx';

import {
	importarVendas, importarInadimplencia,
	getListaApuracao, getListaApuracaoGeral, getListaApuracaoGeralDiario
} from './metaVendaTrimestreActions';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import imagemLogo from '../assets/images/logo.png';

class MetaVendaTrimestreApuracao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		ano: 2025,
		trimestre: 1
	}

	componentWillMount() {
		this.props.getListaApuracao();
		this.props.getListaApuracaoGeral();
		this.props.getListaApuracaoGeralDiario();

		let idInterval = setInterval(() => {
			this.props.getListaApuracao();
			this.props.getListaApuracaoGeral();
			this.props.getListaApuracaoGeralDiario();
		}, 30000);

		this.setState({
			...this.state,
			idInteral: idInterval
		});
	}

	componentWillUnmount() {
		
		clearInterval(this.state.idInteral);
		
	}

    render() {
		console.log(this.props.aguardando);
        return (
            <div>
				
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {

		let apuracaoTrimestre = this.props.listaApuracaoGeral.filter(item => {
			if (this.state.ano && this.state.trimestre) {
				return item.ano == this.state.ano && item.trimestre == this.state.trimestre;
			}
			return false;
		})[0];

		let listaSemanas = [];
		if (apuracaoTrimestre) {
			let data = new Date(apuracaoTrimestre.inicio_trimestre);

			let semana = { id: null, domingo: null, segunda: null, terca: null, quarta: null, quinta: null, sexta: null, sabado: null };
						
			while (data <= new Date(apuracaoTrimestre.fim_trimestre)) {

				if (!semana.id || semana.sabado) {
					if (semana.id) {
						listaSemanas.push(semana);
					}
					semana = { id: listaSemanas.length + 1, domingo: null, segunda: null, terca: null, quarta: null, quinta: null, sexta: null, sabado: null, realizado: data.getTime() <= new Date().getTime() };
				}

				switch (data.getDay()) {
					case 0:
						semana.domingo = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 1:
						semana.segunda = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 2:
						semana.terca = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 3:
						semana.quarta = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 4:
						semana.quinta = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 5:
						semana.sexta = DateFormat.getTimestampParaData(data.getTime());
						break;
					case 6:
						semana.sabado = DateFormat.getTimestampParaData(data.getTime());
						break;
				
					default:
						break;
				}

				data.setDate(data.getDate() + 1);
			}

			listaSemanas.push(semana);

			listaSemanas.sort((a, b) => b.id - a.id);
			
		}
		
		return (
			<ContentCard>
				<ContentCardHeader>

					{(this.props.sessao && this.props.sessao.id == 51 /*TV*/) ? (
						<>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 20 }}>
								<img src={imagemLogo} style={{ width: 500, height: 160 }}/>
							</div>
							<div style={{ textAlign: 'center', marginBottom: 40 }}>
								<h4 style={{ fontSize: 24, fontWeight: 'bold', color: '#1b61ac' }}>META GERAL - TRR VALE DA SERRA</h4>
								<h5 style={{ fontSize: 18, fontWeight: 'bold', color: '#00a859' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
							</div>
						</>
					) : (
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
							<img src={imagemLogo} style={{ width: 250, height: 79 }}/>
							<div style={{ display: 'flex', height: 79, flexDirection: 'column', marginLeft:24, justifyContent: 'center', paddingTop: 25 }}>
								<h4 style={{ fontSize: 18, fontWeight: 'bold', color: '#194682' }}>META GERAL - TRR VALE DA SERRA</h4>
								<h5 style={{ fontSize: 14, fontWeight: 'bold', color: '#194682' }}>NO VALE DO ARAGUAIA, COMBUSTÍVEL É VALE DA SERRA!</h5>
							</div>
						</div>
					)}
					
				</ContentCardHeader>
				<ContentCardBody>
					<Row>

						<Select
							options={[{ id: 2025, valor: 2025 }, { id: 2026, valor: 2026 }]}
							label='Ano'
							cols='12 4 3 2'
							placeholder='Selecione o ano'
							value={this.state.ano}
							onChange={value => {
								this.setState({
									...this.state,
									ano: value
								});
							}} />
								
						<Select
							options={[{ id: 1, valor: '1º trimestre' }, { id: 2, valor: '2º trimestre' }, { id: 3, valor: '3º trimestre' }, { id: 4, valor: '4º trimestre' } ]}
							label='Trimestre'
							cols='12 4 3 2'
							placeholder='Selecione o trimestre'
							value={this.state.trimestre}
							onChange={value => {
								this.setState({
									...this.state,
									trimestre: value
								});
							}} />
					</Row>

					<div
						style={{
							color: '#ffffff',
							backgroundColor: '#194682',
							fontSize: 16,
							fontWeight: 'bold',
							textAlign: 'center',
							padding: 4
						}}>
						TRIMESTRAL
					</div>

					{this.props.listaApuracao.filter(item => {
						if (this.state.ano && this.state.trimestre) {
							return item.ano == this.state.ano && item.trimestre == this.state.trimestre;
						}
						return false;
					}).map(item => {

						let inadimplenciaGanho = 100;
						if (parseFloat(item.inadimplencia_2_maxima || 0) > 0 
							&& parseFloat(item.inadimplencia_realizado || 0) > parseFloat(item.inadimplencia_2_maxima || 0)) {
							inadimplenciaGanho = 100 - parseFloat(item.inadimplencia_1_perda);
						} else if (parseFloat(item.inadimplencia_1_maxima || 0) > 0 
							&& parseFloat(item.inadimplencia_realizado || 0) > parseFloat(item.inadimplencia_1_maxima || 0)) {
							inadimplenciaGanho = 100 - parseFloat(item.inadimplencia_1_perda);
						}
						
						return (
							<Table responsive key={item.id}>
								<THead>
									<Tr color={'#194682'}
										backgroundColor={'#dfe2f5'}>
										<Th fontSize={14} alignCenter></Th>
										<Th alignCenter fontSize={14}>Meta</Th>
										<Th alignCenter fontSize={14}>Realizado</Th>
										<Th alignCenter fontSize={14}>Projetado</Th>
									</Tr>
								</THead>
								<TBody>								
									<Tr>
										<Td width={500} bold>Volume</Td>
										<Td width={100} alignCenter fontSize={14}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.volume_1, 0)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.volume_2, 0)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.volume_3, 0)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.volume_4, 0)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.volume_5, 0)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.volume_5, 0)}</Td>
										<Td width={100} alignCenter fontSize={14} >{FormatUtils.formatarValorTelaDecimal(item.volume_realizado, 0)}</Td>
										<Td width={100} alignCenter fontSize={14}>{FormatUtils.formatarValorTelaDecimal(item.volume_projetado, 0)}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Margem</Td>
										<Td width={100} alignCenter fontSize={14}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.margem_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.margem_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.margem_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.margem_4, 2)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.margem_5, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.margem_5, 2)}</Td>
										<Td width={100} alignCenter fontSize={14} >{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={14}>{FormatUtils.formatarValorTelaDecimal(item.margem_realizado, 2)}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Prazo</Td>
										<Td width={100} alignCenter fontSize={14}
											title={
												`Faixa 1: ${FormatUtils.formatarValorTelaDecimal(item.prazo_1, 2)} \n`
												+ `Faixa 2: ${FormatUtils.formatarValorTelaDecimal(item.prazo_2, 2)} \n`
												+ `Faixa 3: ${FormatUtils.formatarValorTelaDecimal(item.prazo_3, 2)} \n`
												+ `Faixa 4: ${FormatUtils.formatarValorTelaDecimal(item.prazo_4, 2)} \n`
												+ `Faixa 5: ${FormatUtils.formatarValorTelaDecimal(item.prazo_5, 2)} \n`
											}>{FormatUtils.formatarValorTelaDecimal(item.prazo_5, 2)}</Td>
										<Td width={100} alignCenter fontSize={14} >{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={14}>{FormatUtils.formatarValorTelaDecimal(item.prazo_realizado, 2)}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold>Inadimplência</Td>
										<Td width={100} alignCenter fontSize={14}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_1_maxima, 2)}</Td>
										<Td width={100} alignCenter fontSize={14} >{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
										<Td width={100} alignCenter fontSize={14}>{FormatUtils.formatarValorTelaDecimal(item.inadimplencia_realizado, 2)}</Td>
									</Tr>
									<Tr>
										<Td width={500} bold fontSize={14}></Td>
										<Td width={100} alignCenter bold fontSize={14}></Td>
										<Td width={100} alignCenter bold fontSize={14}></Td>
										<Td width={100} alignCenter bold fontSize={14}></Td>
									</Tr>
								</TBody>
							</Table>
						);
					})}

					<div
						style={{
							color: '#ffffff',
							backgroundColor: '#194682',
							fontSize: 16,
							fontWeight: 'bold',
							textAlign: 'center',
							padding: 4
						}}>
						SEMANAL
					</div>
				
					{listaSemanas.filter(semana => semana.realizado).map(semana => {

						const metaVolumeSemana = (semana.domingo ? 0 : 0)
							+ (semana.segunda ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0)
							+ (semana.terca ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0)
							+ (semana.quarta ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0)
							+ (semana.quinta ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0)
							+ (semana.sexta ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0)
							+ (semana.sabado ? parseFloat(apuracaoTrimestre.meta_volume_diario, 0) : 0);

						const resultadoSemana = this.getVolumeData(semana.domingo)
							+ this.getVolumeData(semana.segunda)
							+ this.getVolumeData(semana.terca)
							+ this.getVolumeData(semana.quarta)
							+ this.getVolumeData(semana.quinta)
							+ this.getVolumeData(semana.sexta)
							+ this.getVolumeData(semana.sabado);

						const diasTotalSemana = (semana.domingo ? 0 : 0)
							+ (semana.segunda ? 1 : 0)
							+ (semana.terca ? 1 : 0)
							+ (semana.quarta ? 1 : 0)
							+ (semana.quinta ? 1 : 0)
							+ (semana.sexta ? 1 : 0)
							+ (semana.sabado ? 1 : 0);
						
						const diasSemanaRealizados = 
							(semana.domingo ? 0 : 0)
							+ (semana.segunda && semana.segunda < DateFormat.getDataAtual() ? 1 : 0)
							+ (semana.terca && semana.terca < DateFormat.getDataAtual() ? 1 : 0)
							+ (semana.quarta && semana.quarta < DateFormat.getDataAtual() ? 1 : 0)
							+ (semana.quinta && semana.quinta < DateFormat.getDataAtual() ? 1 : 0)
							+ (semana.sexta && semana.sexta < DateFormat.getDataAtual() ? 1 : 0)
							+ (semana.sabado && semana.sabado < DateFormat.getDataAtual() ? 1 : 0);

						const volumeProjetado = diasSemanaRealizados ? (resultadoSemana * diasTotalSemana) / diasSemanaRealizados : 0;

						const margemSemana = resultadoSemana <= 0 ? 0 : (
							(this.getMargemData(semana.domingo) * this.getVolumeData(semana.domingo))
							+ (this.getMargemData(semana.segunda) * this.getVolumeData(semana.segunda))
							+ (this.getMargemData(semana.terca) * this.getVolumeData(semana.terca))
							+ (this.getMargemData(semana.quarta) * this.getVolumeData(semana.quarta))
							+ (this.getMargemData(semana.quinta) * this.getVolumeData(semana.quinta))
							+ (this.getMargemData(semana.sexta) * this.getVolumeData(semana.sexta))
							+ (this.getMargemData(semana.sabado) * this.getVolumeData(semana.sabado))
						) / resultadoSemana;

						return (
							<Table key={semana.id}>
								<THead>
									<Tr backgroundColor={'#dfe2f5'}>
										<Th width={100}  fontSize={14} alignCenter>Semana {semana.id}</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.domingo && <><div>Domingo</div><div>{DateFormat.formatarDataSqlParaTela(semana.domingo)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.segunda && <><div>Segunda</div><div>{DateFormat.formatarDataSqlParaTela(semana.segunda)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.terca && <><div>Terça</div><div>{DateFormat.formatarDataSqlParaTela(semana.terca)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.quarta && <><div>Quarta</div><div>{DateFormat.formatarDataSqlParaTela(semana.quarta)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.quinta && <><div>Quinta</div><div>{DateFormat.formatarDataSqlParaTela(semana.quinta)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.sexta && <><div>Sexta</div><div>{DateFormat.formatarDataSqlParaTela(semana.sexta)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>
											{semana.sabado && <><div>Sábado</div><div>{DateFormat.formatarDataSqlParaTela(semana.sabado)}</div></>}
										</Th>
										<Th width={100} fontSize={14} alignCenter>Margem</Th>
										<Th width={100} fontSize={14} alignCenter>Projetado</Th>
										<Th width={100} fontSize={14} alignCenter>Realizado</Th>
									</Tr>
								</THead>
								<TBody>
									<Tr>
										<Td width={100}  fontSize={14} alignCenter bold>Meta</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.domingo ? FormatUtils.formatarValorTelaDecimal(0, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.segunda ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.terca ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.quarta ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.quinta ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.sexta ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.sabado ? FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_volume_diario, 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={'#9e9e9e'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(apuracaoTrimestre.meta_margem, 2)}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={'#9e9e9e'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(metaVolumeSemana, 0)}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={'#9e9e9e'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(metaVolumeSemana, 0)}</Td>
									</Tr>
									<Tr>
										<Td width={100}  fontSize={14} alignCenter bold>Resultado</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.domingo ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.domingo), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.segunda ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.segunda), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.terca ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.terca), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.quarta ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.quarta), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.quinta ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.quinta), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.sexta ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.sexta), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter>{semana.sabado ? FormatUtils.formatarValorTelaDecimal(this.getVolumeData(semana.sabado), 0) : ''}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={margemSemana >= parseFloat(apuracaoTrimestre.meta_margem) ? '#009c39' : '#de003b'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(margemSemana, 2)}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={volumeProjetado >= metaVolumeSemana ? '#009c39' : '#de003b'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(volumeProjetado, 0)}</Td>
										<Td width={100} fontSize={14} alignCenter backgroundColor={resultadoSemana >= metaVolumeSemana ? '#009c39' : '#de003b'} color={'#ffffff'}>{FormatUtils.formatarValorTelaDecimal(resultadoSemana, 0)}</Td>
									</Tr>
								</TBody>
							</Table>
						);
					})}
				</ContentCardBody>
			</ContentCard>
		);
	}

	getVolumeData(data) {
		let registro = this.props.listaApuracaoGeralDiario.filter(item => item.data_emissao == data)[0];

		return parseFloat(registro ? registro.quantidade : 0);
	}

	getMargemData(data) {
		let registro = this.props.listaApuracaoGeralDiario.filter(item => item.data_emissao == data)[0];

		return parseFloat(registro ? registro.margem : 0);
	}

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.metaVendaTrimestre.modoTela,
	aguardando: state.metaVendaTrimestre.aguardando,
	filtro: state.metaVendaTrimestre.filtro,
	listaApuracao: state.metaVendaTrimestre.listaApuracao.filter(item => item.geral),
	listaApuracaoGeral: state.metaVendaTrimestre.listaApuracaoGeral,
	listaApuracaoGeralDiario: state.metaVendaTrimestre.listaApuracaoGeralDiario
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	importarVendas, importarInadimplencia,
	getListaApuracao, getListaApuracaoGeral, getListaApuracaoGeralDiario
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MetaVendaTrimestreApuracao);
