import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './saidaForm';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidade, getListaTanque, getListaVeiculo, getListaMotorista } from './saidaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class Saida extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: '',
		dataInicial: DateFormat.formatarDataSqlParaTela(DateFormat.getTimestampParaData(new Date().getTime() - 604800000)),
		dataFinal: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaProduto();
		this.props.getListaUnidade();
		this.props.getListaTanque();
		this.props.getListaVeiculo();
		this.props.getListaMotorista();

		let idInterval = setInterval(() => {
			this.props.getLista();
			this.props.getListaProduto();
			this.props.getListaUnidade();
			this.props.getListaTanque();
			this.props.getListaVeiculo();
			this.props.getListaMotorista();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
	}
	
    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({  saida: true, ponto_atendimento: false, vendedor: false });
								}} />
						</Grid>
						<Grid cols='6 6 3 2'>
							<ExcelFile
								filename={'Saídas'}
								element={
									<Button
									text='Exportar'
									type={'primary'}
									icon={'fa fa-plus'} />
								}>
								<ExcelSheet data={this.props.lista.map(item => ({
									...item,
									datahora_saida: DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida),
									quantidade: FormatUtils.formatarValorTela(item.quantidade, 2),
									valor: FormatUtils.formatarValorTela(item.valor, 2)
								}))} name='Saídas'>
									<ExcelColumn label='Data Hora' value='datahora_saida' />
									<ExcelColumn label='Tipo' value='nome_tipo' />
									<ExcelColumn label='Unidade' value='nome_unidade' />
									<ExcelColumn label='Produto' value='nome_produto' />
									<ExcelColumn label='Veículo' value='placa_veiculo' />
									<ExcelColumn label='Motorista' value='nome_motorista' />
									<ExcelColumn label='Quantidade' value='quantidade' />
									<ExcelColumn label='Valor' value='valor' />
									<ExcelColumn label='Odometro' value='hodometro' />
									<ExcelColumn label='Tanque Cheio' value='tanque_cheio' />
								</ExcelSheet>
							</ExcelFile>
						</Grid>
					</Row>

				</ContentCardHeader>
				<ContentCardBody>

					<Row>
						<LabelAndInputMask
							name='data_inicial'
							label='Data Inicial' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataInicial}
							onChange={data => {
								this.setState({
									...this.state,
									dataInicial: data.target.value
								});
							}} />

						<LabelAndInputMask
							name='data_final'
							label='Data Final' placeholder='Informe a data'
							cols='12 6 2 2'
							mask='99/99/9999'
							value={this.state.dataFinal}
							onChange={data => {
								this.setState({
									...this.state,
									dataFinal: data.target.value
								});
							}} />
					</Row>
					
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th colspan={1}>Data Hora</Th>
								<Th colspan={1} alignCenter>Tipo</Th>
								<Th colspan={1} alignCenter>Unidade</Th>
								<Th colspan={1} alignCenter>Produto</Th>
								<Th colspan={1} alignCenter>Veículo</Th>
								<Th colspan={1} alignCenter>Motorista</Th>
								<Th colspan={1} alignRight>Quantidade</Th>
								<Th colspan={1} alignRight>Valor</Th>
								<Th colspan={1} alignRight>Odometro</Th>
								<Th colspan={1} alignCenter>Tanque Cheio</Th>
								<Th colspan={1} alignCenter>Inclusão</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_tipo} ${item.nome_unidade || ''} ${item.nome_produto || ''} ${item.placa_veiculo || ''} ${item.nome_motorista || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.state.dataInicial
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora_saida)) < DateFormat.formatarDataTelaParaSql(this.state.dataInicial)) {
									return false;
								}
								if (this.state.dataFinal
									&& DateFormat.formatarDataTelaParaSql(DateFormat.formatarDataSqlParaTela(item.datahora_saida)) > DateFormat.formatarDataTelaParaSql(this.state.dataFinal)) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_saida)}</Td>
									<Td alignCenter>{item.nome_tipo}</Td>
									<Td alignCenter>{item.nome_unidade}</Td>									
									<Td alignCenter>{item.nome_produto}</Td>
									<Td alignCenter>{item.placa_veiculo}</Td>
									<Td alignCenter>{item.nome_motorista}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.quantidade, 2)}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.valor, 2)}</Td>
									<Td alignRight>{FormatUtils.formatarValorTela(item.hodometro, 0)}</Td>
									<Td alignCenter>{item.tanque_cheio ? 'SIM' : 'NÃO'}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2)
												});
												this.props.initForm({
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2)
												});
												this.props.initForm({
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 2)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.saida,
	modoTela: state.saida.modoTela,
	lista: state.saida.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaProduto, getListaUnidade, getListaTanque, getListaVeiculo, getListaMotorista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Saida);
