import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './manutencaoForm';
import ReactExport from 'react-export-excel-fixed-xlsx';

import { setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaOficina, getListaVeiculo } from './manutencaoActions';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

class Manutencao extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTipo();
		this.props.getListaOficina();
		this.props.getListaVeiculo();

		let idInterval = setInterval(() => {
			this.props.getLista();
			this.props.getListaTipo();
			this.props.getListaOficina();
			this.props.getListaVeiculo();
		}, 60000);
		
		this.setState({
			...this.state,
			idInterval: idInterval
		});
	}
	
	componentWillUnmount() {

		if (this.state.idInterval) {
			clearInterval(this.state.idInterval);
		}
	
	}

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		// let listaExportacao = [];
		// this.props.lista.forEach(item => {
		// 	(item.itens || []).forEach(manutencaoItem => {
		// 		listaExportacao.push({
		// 			...item,
		// 			id: `${item.id}-${manutencaoItem.id}`,					
		// 			datahora_programacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_programacao),
		// 			datahora_carga: item.datahora_carga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_carga) : null,
		// 			datahora_previsao_chegada: item.datahora_previsao_chegada ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao_chegada) : null,
		// 			datahora_descarga: item.datahora_descarga ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_descarga) : null,
		// 			nome_produto: manutencaoItem.nome_produto,
		// 			quantidade: FormatUtils.formatarValorTela(manutencaoItem.quantidade, 2)
		// 		});
		// 	});
		// });
	
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th alignCenter colspan={1}>Agendamento</Th>
								<Th alignCenter colspan={1}>Status</Th>
								<Th colspan={1}>Veículo</Th>
								<Th colspan={1}>Tipo</Th>
								<Th colspan={1}>Oficina</Th>
								<Th colspan={1}>OS</Th>
								<Th colspan={1}>Descrição</Th>
								<Th colspan={1} alignCenter>Previsão</Th>
								<Th colspan={1} alignCenter>Inicio</Th>
								<Th colspan={1} alignCenter>Teste</Th>
								<Th colspan={1} alignCenter>Liberado</Th>
								<Th colspan={1}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${item.placa_veiculo} ${item.nome_oficina || ''} ${item.os || ''} ${item.descricao || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento)}</Td>
									<Td alignCenter backgroundColor={item.datahora_entrada ? null : '#cff000'}>{item.datahora_entrada ? 'Em Andamento' : 'Agendada'}</Td>
									<Td>{item.placa_veiculo}</Td>
									<Td>{item.nome_tipo}</Td>
									<Td>{item.nome_oficina}</Td>
									<Td>{item.os}</Td>
									<Td>{item.descricao}</Td>
									<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}</Td>
									<Td alignCenter>{item.datahora_entrada ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada) : (
										<ButtonTable
											type={'success'}
											icon={'fas fa-check'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.salvar({
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao),
												});
											}} />
									)}</Td>
									<Td alignCenter>{item.datahora_teste ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste) : (
										<ButtonTable
											type={'success'}
											icon={'fas fa-check'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!item.datahora_entrada}
											event={() => {
												this.props.salvar({
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao),
												});
											}} />
									)}</Td>
									<Td alignCenter>{item.datahora_liberacao ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao) : (
										<ButtonTable
											type={'success'}
											icon={'fas fa-check'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={!item.datahora_teste}
											event={() => {
												this.props.salvar({
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
												});
											}} />
									)}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao)
												});
												this.props.initForm({
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao),
												});
												this.props.initForm({
													...item,
													datahora_agendamento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_agendamento),
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													datahora_previsao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao),
													datahora_teste: DateFormat.formatarDataHoraSqlParaTela(item.datahora_teste),
													datahora_liberacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_liberacao),
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.manutencao,
	modoTela: state.manutencao.modoTela,
	lista: state.manutencao.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, getListaTipo, getListaOficina, getListaVeiculo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Manutencao);
