import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import FormatUtils from '../common/formatUtils/FormatUtils';
import imagemUser from '../assets/images/user.jpg';
import { 
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, 
	getListaUnidade, getListaProduto , getListaPosicaoVeiculo, getListaManual
} from './dashboardActions';

import contentCardBody from '../common/template/contentCardBody';

class Dashboard extends Component {

	state = {
		pesquisar: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getListaUnidade();
		this.props.getListaProduto();
		this.props.getLista();
		this.props.getListaTerceiros();
		this.props.getListaEntradas();
		this.props.getListaSaidas();
		this.props.getListaTransferencias();
		this.props.getListaPosicaoVeiculo();
		this.props.getListaManual();
    }

    render() {

		let totalS10EstoqueManual = 0;
		let totalS10EstoqueBase = 0;
		let totalS10SobreRodas = 0;
		let totalS10SobreRodasBase = 0;
		let totalS10Faturar = 0;
		let totalS10Faturado = 0;
		let totalS10Disponibilidade = 0;
		let totalS10Programado = 0;

		let totalS500EstoqueManual = 0;
		let totalS500EstoqueBase = 0;
		let totalS500SobreRodas = 0;
		let totalS500SobreRodasBase = 0;
		let totalS500Faturar = 0;
		let totalS500Faturado = 0;
		let totalS500Disponibilidade = 0;
		let totalS500Programado = 0;

		const configuracaoTanques = [{
			id: 1,
			nome: 'Capacidade: 80m³',
			id_unidade: 1,
			id_produto: 1,
			capacidade: 20000
		},
		//1-ÁGUA BOA
		//3-S500
		{
			id: 5,
			nome: 'Capacidade: 40m³',
			id_unidade: 1,
			id_produto: 3,
			capacidade: 20000
		},
		//3-BARRA DO GARÇAS
		//1-S10		
		{
			id: 7,
			nome: 'Capacidade: 180m³',
			id_unidade: 3,
			id_produto: 1,
			capacidade: 60000
		},
		//3-BARRA DO GARÇAS
		//3-S500
		{
			id: 10,
			nome: 'Capacidade: 120m³',
			id_unidade: 3,
			id_produto: 3,
			capacidade: 60000
		},
		//9-PORTO ALEGRE DO NORTE
		//1-S10		
		{
			id: 12,
			nome: 'Capacidade: 150m³',
			id_unidade: 9,
			id_produto: 1,
			capacidade: 60000
		},
		//9-PORTO ALEGRE DO NORTE
		//3-S500
		{
			id: 15,
			nome: 'Capacidade: 90m³',
			id_unidade: 9,
			id_produto: 3,
			capacidade: 60000
		}];

        return (
			<Content>

				{this.state.itens ? this.modalItens() : null}

				{this.props.listaUnidade.filter(unidade => !unidade.valor.toUpperCase().includes('DIRETA') && this.props.lista.filter(item => item.id_unidade == unidade.id).length).map(unidade => {

					let totalEstoqueManual = 0;
					let totalEstoqueBase = 0;
					let totalSobreRodas = 0;
					let totalSobreRodasBase = 0;
					let totalFaturar = 0;
					let totalFaturado = 0;
					let totalDisponibilidade = 0;
					let totalProgramado = 0;

					return (
						<ContentCard key={unidade.id}>
							<ContentCardHeader>
								<label>Saldos - {unidade.valor}</label>
							</ContentCardHeader>
							<contentCardBody>
								<Table responsive>
									<THead>
										<Tr>
											<Th alignCenter></Th>
											{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
											<Th alignCenter>Estoque Manual</Th>*/} 
											<Th alignCenter>Estoque Base</Th>											
											<Th alignCenter>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<div style={{ width: '50%' }}>Sobre Rodas Viagem</div>
													<div style={{ width: '50%' }}>Sobre Rodas Base</div>
												</div>
											</Th>
											<Th alignCenter>À Faturar</Th>
											<Th alignCenter>Faturado (não carregado)</Th>
											<Th alignCenter>Disponibilidade</Th>
											<Th alignCenter>Programado para Estoque</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.listaProduto.map(produto => {

											let registroManual = null;//this.props.listaManual.filter(manual => manual.id_unidade == unidade.id && manual.id_produto == produto.id).pop();

											let estoqueManual = parseFloat(registroManual ? registroManual.quantidade : 0);
											
											let tanques = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'ESTOQUE BASE')[0]
											let rodas = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'SOBRE RODAS')[0]
											let faturar = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'À FATURAR')[0]
											let faturado = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'FATURADO (NÃO CARREGADO)')[0]

											let acessoPedro = false;//(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4));

											let disponibilidade = (acessoPedro ? estoqueManual : parseFloat(tanques ? tanques.quantidade : 0)) + parseFloat(rodas ? rodas.quantidade : 0) 
												- parseFloat(faturar ? faturar.quantidade : 0) - parseFloat(faturado ? faturado.quantidade : 0);
											let programado = this.props.lista.filter(item => item.id_unidade == unidade.id && item.id_produto == produto.id && item.status == 'PROGRAMADO')[0]

											let listaAux = this.props.lista.filter(item => item.id_unidade == unidade.id 
												&& item.id_produto == produto.id
												&& item.status != 'PROGRAMADO'
											);
											
											totalEstoqueManual += estoqueManual;
											totalEstoqueBase += parseFloat(listaAux[0].quantidade || 0);
											totalSobreRodas += parseFloat(listaAux[1].quantidade || 0);
											totalSobreRodasBase += parseFloat(listaAux[1].quantidade_sobre_rodas_base || 0);
											totalFaturar += parseFloat(listaAux[2].quantidade || 0);
											totalFaturado += parseFloat(listaAux[3].quantidade || 0);
											totalDisponibilidade += parseFloat(disponibilidade || 0);
											totalProgramado += parseFloat(programado ? programado.quantidade : 0);

											if (produto.id == 1) {
												totalS10EstoqueManual += estoqueManual;
												totalS10EstoqueBase += parseFloat(listaAux[0].quantidade || 0);
												totalS10SobreRodas += parseFloat(listaAux[1].quantidade || 0);
												totalS10SobreRodasBase += parseFloat(listaAux[1].quantidade_sobre_rodas_base || 0);
												totalS10Faturar += parseFloat(listaAux[2].quantidade || 0);
												totalS10Faturado += parseFloat(listaAux[3].quantidade || 0);
												totalS10Disponibilidade += parseFloat(disponibilidade || 0);
												totalS10Programado += parseFloat(programado ? programado.quantidade : 0);
											} else {
												totalS500EstoqueManual += estoqueManual;
												totalS500EstoqueBase += parseFloat(listaAux[0].quantidade || 0);
												totalS500SobreRodas += parseFloat(listaAux[1].quantidade || 0);
												totalS500SobreRodasBase += parseFloat(listaAux[1].quantidade_sobre_rodas_base || 0);
												totalS500Faturar += parseFloat(listaAux[2].quantidade || 0);
												totalS500Faturado += parseFloat(listaAux[3].quantidade || 0);
												totalS500Disponibilidade += parseFloat(disponibilidade || 0);
												totalS500Programado += parseFloat(programado ? programado.quantidade : 0);
											}

											let estoqueMinimo = 0;
											let corEstoqueMinimo = '';
											if (produto.id == 1) {
												estoqueMinimo = unidade.estoque_minimo_s10;
											} else {
												estoqueMinimo = unidade.estoque_minimo_s500;
											}
											if (parseFloat(disponibilidade || 0) <= parseFloat(estoqueMinimo)) {
												corEstoqueMinimo = '#f55f5f';
											} else if (parseFloat(disponibilidade || 0) <= parseFloat(estoqueMinimo) + (parseFloat(estoqueMinimo || 0) * 1.3)) {
												corEstoqueMinimo = '#f5e727';
											} else {
												corEstoqueMinimo = '#05eb73';
											}
											
											return (
												<Tr key={produto.id}>

													<Td alignCenter backgroundColor={produto.id == 1 ? '#FFFDD0' : '#f2c2b8'}
														title={
															configuracaoTanques.filter(tanque => tanque.id_unidade == unidade.id && tanque.id_produto == produto.id).map(tanque => {
																return `${tanque.nome} \n`;
															}).join('\n')
														}>{produto.valor}</Td>
													{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
													<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(estoqueManual, 0)}</Td>*/}											
												
													{this.props.lista.filter(item => item.id_unidade == unidade.id 
														&& item.id_produto == produto.id
														&& item.status != 'PROGRAMADO'
													).map((item, i) => {

														let lista = [];
														if (item.status == 'SOBRE RODAS') {
															lista = this.props.listaEntrada.filter(entrada => 
																entrada.id_unidade == item.id_unidade
																&& entrada.id_produto == item.id_produto
																&& entrada.status == item.status
															);

															lista = lista.concat(this.props.listaTransferencia.filter(transferencia => 
																(transferencia.id_unidade_destino == item.id_unidade)
																&& transferencia.id_produto == item.id_produto
																&& transferencia.status == item.status
															));
														} else if (item.status == 'À FATURAR' || item.status == 'FATURADO (NÃO CARREGADO)') {
															lista = this.props.listaSaida.filter(saida => 
																saida.id_unidade == item.id_unidade
																&& saida.id_produto == item.id_produto
																&& saida.status == item.status
															);
														}

														return (
															<Td key={i} alignCenter
																title={
																	lista.map(mov => {

																		let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];
																		
																		return `${mov.giro ? `GIRO ${mov.giro} | ` : ''}${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`;
																	}).join('\n')
																}
																onClick={() => {
																	if (!lista.length) {
																		// window.alert('Nenhum registro encontrado');
																	} else {
																		this.setDialogItens(lista.map(mov => {

																			let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];
	
																			return {
																				descricao: `${mov.giro ? `GIRO ${mov.giro} | ` : ''}${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`,
																				latitude: telemetria && telemetria.telemetria ? telemetria.telemetria.latitude : null,
																				longitude: telemetria && telemetria.telemetria ? telemetria.telemetria.longitude : null
																			}
																		}));
																		// window.alert(
																		// 	lista.map(mov => {

																		// 		let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == mov.placa_veiculo)[0];

																		// 		return `${mov.placa_veiculo || 'Sem Veículo'} | ${mov.nome_motorista || (telemetria && telemetria.telemetria ? telemetria.telemetria.motorista : null) || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${mov.nome_distribuidora || mov.nome_cliente || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(mov.quantidade, 0)} litros \n`;
																		// 	}).join('\n')
																		// );
																	}
																}}
																>{item.status != 'SOBRE RODAS' ? (
																	FormatUtils.formatarValorTelaDecimal(parseFloat(item.quantidade), 0)
																) : (
																	<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
																		<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(parseFloat(item.quantidade) - parseFloat(item.quantidade_sobre_rodas_base || 0), 0)}</div>
																		<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(item.quantidade_sobre_rodas_base, 0)}</div>
																	</div>
																)}
															</Td>
														);
													})}
													<Td alignCenter backgroundColor={corEstoqueMinimo}>{FormatUtils.formatarValorTelaDecimal(disponibilidade, 0)}</Td>
													<Td title={
															this.props.listaEntrada.filter(entrada => 
																entrada.id_unidade == unidade.id
																&& entrada.id_produto == produto.id
																&& entrada.status == 'PROGRAMADO'
															).map(entrada => {
																
																let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == entrada.placa_veiculo)[0];

																return `${entrada.placa_veiculo || 'Sem Veículo'} | ${entrada.nome_motorista || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${entrada.nome_distribuidora || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(entrada.quantidade, 0)} litros`
															}).join('\n')
															+ '\n' + 
															this.props.listaTransferencia.filter(transferencia => 
																(transferencia.id_unidade_destino == unidade.id)
																&& transferencia.id_produto == produto.id
																&& transferencia.status == 'PROGRAMADO'
															).map(transferencia => {

																let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == transferencia.placa_veiculo)[0];

																return `${transferencia.placa_veiculo || 'Sem Veículo'} | ${transferencia.nome_motorista || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${transferencia.nome_distribuidora || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(transferencia.quantidade, 0)} litros`
															}).join('\n')
														}
														onClick={() => {
															let lista = this.props.listaEntrada.filter(entrada => 
																entrada.id_unidade == unidade.id
																&& entrada.id_produto == produto.id
																&& entrada.status == 'PROGRAMADO'
															);
															lista = lista.concat(this.props.listaTransferencia.filter(transferencia => 
																(transferencia.id_unidade_destino == unidade.id)
																&& transferencia.id_produto == produto.id
																&& transferencia.status == 'PROGRAMADO'
															));

															if (!lista.length) {
																// window.alert('Nenhum registro encontrado');
															} else {
																this.setDialogItens(lista.map(entrada => {

																	let telemetria = this.props.listaPosicaoVeiculo.filter(telemetria => telemetria.placa == entrada.placa_veiculo)[0];

																	return {
																		descricao: `${entrada.placa_veiculo || 'Sem Veículo'} | ${entrada.nome_motorista || 'Sem Motorista'}${telemetria && telemetria.telemetria && telemetria.telemetria.localizacao ? ` | ${telemetria.telemetria.localizacao}` : ''} | ${entrada.nome_distribuidora || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(entrada.quantidade, 0)} litros`,
																		latitude: telemetria && telemetria.telemetria ? telemetria.telemetria.latitude : null,
																		longitude: telemetria && telemetria.telemetria ? telemetria.telemetria.longitude : null
																	}
																}));
																// window.alert(
																// 	lista.map(entrada => (
																// 		`${entrada.placa_veiculo || 'Sem Veículo'} | ${entrada.nome_motorista || 'Sem Motorista'} | ${entrada.nome_distribuidora || 'Sem Distribuidora'} | ${FormatUtils.formatarValorTela(entrada.quantidade, 0)} litros`
																// 	)).join('\n')
																// );
															}
														}}
														alignCenter backgroundColor={'#9cd4ff'}>{FormatUtils.formatarValorTelaDecimal(programado ? programado.quantidade : 0, 0)}</Td>
												
												</Tr>
											);
										})}
										
										<Tr backgroundColor={'#f2f2f0'}>
											<Td alignRight width={100} bold fontSize={16}>TOTAL</Td>
											{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
											<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueManual, 0)}</Td>*/}
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalEstoqueBase, 0)}</Td>
											<Td alignCenter width={200} bold fontSize={16}>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
													<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalSobreRodas - totalSobreRodasBase, 0)}</div>
													<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalSobreRodasBase, 0)}</div>
												</div>
											</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalFaturar, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalFaturado, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalDisponibilidade, 0)}</Td>
											<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalProgramado, 0)}</Td>
										</Tr>
										
									</TBody>
								</Table>			
							</contentCardBody>
						</ContentCard>
					);
				})}
				
				<ContentCard>
					<ContentCardHeader>
						<label>Total das Unidades</label>
					</ContentCardHeader>
					<contentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th alignCenter></Th>
									{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
									<Th alignCenter>Estoque Manual</Th>*/} 
									<Th alignCenter>Estoque Base</Th>
									<Th alignCenter>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ width: '50%' }}>Sobre Rodas Viagem</div>
											<div style={{ width: '50%' }}>Sobre Rodas Base</div>
										</div>
									</Th>
									<Th alignCenter>À Faturar</Th>
									<Th alignCenter>Faturado (não carregado)</Th>
									<Th alignCenter>Disponibilidade</Th>
									<Th alignCenter>Programado para Estoque</Th>	
								</Tr>
							</THead>
							<TBody>
								<Tr>
									<Td alignCenter width={100} backgroundColor={'#FFFDD0'}>B S10 ADITIVADO</Td>
									{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueManual, 0)}</Td>*/}
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueBase, 0)}</Td>
									<Td alignCenter width={200}>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodas - totalS10SobreRodasBase, 0)}</div>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodasBase, 0)}</div>
										</div>
									</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturar, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturado, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10Disponibilidade, 0)}</Td>
									<Td alignCenter width={100} backgroundColor={'#9cd4ff'}>{FormatUtils.formatarValorTelaDecimal(totalS10Programado, 0)}</Td>
								</Tr>	
								<Tr>
									<Td alignCenter width={100} backgroundColor={'#f2c2b8'}>B S500 ADITIVADO</Td>
									{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueManual, 0)}</Td>*/}
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500EstoqueBase, 0)}</Td>
									<Td alignCenter width={200}>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS500SobreRodas - totalS500SobreRodasBase, 0)}</div>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS500SobreRodasBase, 0)}</div>
										</div>
									</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500Faturar, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500Faturado, 0)}</Td>
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS500Disponibilidade, 0)}</Td>
									<Td alignCenter width={100} backgroundColor={'#9cd4ff'}>{FormatUtils.formatarValorTelaDecimal(totalS500Programado, 0)}</Td>
								</Tr>			
								<Tr backgroundColor={'#f2f2f0'}>
									<Td alignRight width={100} bold fontSize={16}>TOTAL</Td>
									{/*(this.props.sessao && (this.props.sessao.id == 1 || this.props.sessao.id == 4)) &&
									<Td alignCenter width={100}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueManual + totalS500EstoqueManual, 0)}</Td>*/}
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10EstoqueBase + totalS500EstoqueBase, 0)}</Td>
									<Td alignCenter width={200} bold fontSize={16}>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodas + totalS500SobreRodas - totalS10SobreRodasBase - totalS500SobreRodasBase, 0)}</div>
											<div style={{ width: '50%' }}>{FormatUtils.formatarValorTelaDecimal(totalS10SobreRodasBase + totalS500SobreRodasBase, 0)}</div>
										</div>
									</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturar + totalS500Faturar, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10Faturado + totalS500Faturado, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10Disponibilidade + totalS500Disponibilidade, 0)}</Td>
									<Td alignCenter width={100} bold fontSize={16}>{FormatUtils.formatarValorTelaDecimal(totalS10Programado + totalS500Programado, 0)}</Td>
								</Tr>								
							</TBody>
						</Table>			
					</contentCardBody>
				</ContentCard>

				{this.props.listaUnidade.filter(unidade => this.props.listaTerceiro.filter(item => item.id_unidade == unidade.id).length).map(unidade => (
					<ContentCard key={unidade.id}>
						<ContentCardHeader>
							<label>Empréstimos - {unidade.valor}</label>
						</ContentCardHeader>
						<contentCardBody>
							<Row style={{ padding: 12 }}>
								{this.props.listaTerceiro.filter(item => item.id_unidade == unidade.id).map((item, i) => (
									<div key={i} class='col-lg-3 col-6'>
										<div class={`small-box`} style={{ background: item.id_produto == 1 ? '#FFFDD0' : '#f2c2b8'}}>
											<div class='inner'>
												<h4>{FormatUtils.formatarValorTelaDecimal(item.quantidade, 0)} L - {item.id_produto == 1 ? 'S10' : 'S500'}</h4>
												<p></p>
												<p>{item.nome_terceiro}</p>
											</div>
											<div class='icon'>
												<i class={'fas fa-gas-users'}></i>
											</div>
										</div>
									</div>
								))}
							</Row>
						</contentCardBody>
					</ContentCard>
				))}
			</Content>
        )
	}

	setDialogItens(itens) {
		this.setState({
			...this.state,
			itens: itens
		})
	}
	
	modalItens() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'></h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setDialogItens(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: '80%' }}>
							<Row>
								{(this.state.itens || []).map(item => (
									<p key={item.descricao}>
										{item.descricao}
										{(item.latitude && item.longitude) &&
										<a style={{ paddingLeft: 4 }} href={`https://www.google.com/maps/place/${item.latitude},${item.longitude}`} target='_blank'>(Abrir no Mapa)</a>}
									</p>
								))}								
							</Row>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => {
									this.setDialogItens(null);
								}}>Ok</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboard.aguardando,
	lista: state.dashboard.lista,
	listaTerceiro: state.dashboard.listaTerceiro,
	listaEntrada: state.dashboard.listaEntrada,
	listaSaida: state.dashboard.listaSaida,
	listaTransferencia: state.dashboard.listaTransferencia,
	listaUnidade: state.dashboard.listaUnidade,
	listaProduto: state.dashboard.listaProduto,
	listaPosicaoVeiculo: state.dashboard.listaPosicaoVeiculo,
	listaManual: state.dashboard.listaManual
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getLista, getListaTerceiros, getListaEntradas, getListaSaidas, getListaTransferencias, getListaUnidade, getListaProduto, getListaPosicaoVeiculo, getListaManual
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
