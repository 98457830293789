const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	aguardando: false,
	lista: [],
	listaTipo: [],
	listaItem: [],
	listaAno: [
		{ id: 2024, valor: '2024', dataInicial: '2024/01/01', dataFinal: '2024/12/31' },
		{ id: 2025, valor: '2025', dataInicial: '2025/01/01', dataFinal: '2025/12/31' },
		{ id: 2026, valor: '2026', dataInicial: '2026/01/01', dataFinal: '2026/12/31' },
		{ id: 2027, valor: '2027', dataInicial: '2027/01/01', dataFinal: '2027/12/31' }
	],
	listaSemestre: [
		{ id: 202401, valor: '1º 2024', dataInicial: '2024/01/01', dataFinal: '2024/06/30' },
		{ id: 202402, valor: '2º 2024', dataInicial: '2024/07/01', dataFinal: '2024/12/31' },
		
		{ id: 202501, valor: '1º 2025', dataInicial: '2025/01/01', dataFinal: '2025/06/30' },
		{ id: 202502, valor: '2º 2025', dataInicial: '2025/07/01', dataFinal: '2025/12/31' },

		{ id: 202601, valor: '1º 2026', dataInicial: '2026/01/01', dataFinal: '2026/06/30' },
		{ id: 202602, valor: '2º 2026', dataInicial: '2026/07/01', dataFinal: '2026/12/31' },

		{ id: 202701, valor: '1º 2027', dataInicial: '2027/01/01', dataFinal: '2027/06/30' },
		{ id: 202702, valor: '2º 2027', dataInicial: '2027/07/01', dataFinal: '2027/12/31' }
	],
	listaTrimestre: [
		{ id: 202401, valor: '1º 2024', dataInicial: '2024/01/01', dataFinal: '2024/03/31' }, 
		{ id: 202402, valor: '2º 2024', dataInicial: '2024/04/01', dataFinal: '2024/06/30' }, 
		{ id: 202403, valor: '3º 2024', dataInicial: '2024/07/01', dataFinal: '2024/09/30' }, 
		{ id: 202404, valor: '4º 2024', dataInicial: '2024/10/01', dataFinal: '2024/12/31' },

		{ id: 202501, valor: '1º 2025', dataInicial: '2025/01/01', dataFinal: '2025/03/31' }, 
		{ id: 202502, valor: '2º 2025', dataInicial: '2025/04/01', dataFinal: '2025/06/30' }, 
		{ id: 202503, valor: '3º 2025', dataInicial: '2025/07/01', dataFinal: '2025/09/30' }, 
		{ id: 202504, valor: '4º 2025', dataInicial: '2025/10/01', dataFinal: '2025/12/31' },

		{ id: 202601, valor: '1º 2026', dataInicial: '2026/01/01', dataFinal: '2026/03/31' }, 
		{ id: 202602, valor: '2º 2026', dataInicial: '2026/04/01', dataFinal: '2026/06/30' }, 
		{ id: 202603, valor: '3º 2026', dataInicial: '2026/07/01', dataFinal: '2026/09/30' }, 
		{ id: 202604, valor: '4º 2026', dataInicial: '2026/10/01', dataFinal: '2026/12/31' },

		{ id: 202701, valor: '1º 2027', dataInicial: '2027/01/01', dataFinal: '2027/03/31' }, 
		{ id: 202702, valor: '2º 2027', dataInicial: '2027/04/01', dataFinal: '2027/06/30' }, 
		{ id: 202703, valor: '3º 2027', dataInicial: '2027/07/01', dataFinal: '2027/09/30' }, 
		{ id: 202704, valor: '4º 2027', dataInicial: '2027/10/01', dataFinal: '2027/12/31' }
	],
	listaMes: [
		{ id: 202401, valor: 'Janeiro 2024', dataInicial: '2024/01/01', dataFinal: '2024/01/31', }, 
		{ id: 202402, valor: 'Fevereiro 2024', dataInicial: '2024/02/01', dataFinal: '2024/02/29', }, 
		{ id: 202403, valor: 'Março 2024', dataInicial: '2024/03/01', dataFinal: '2024/03/31', }, 
		{ id: 202404, valor: 'Abril 2024', dataInicial: '2024/04/01', dataFinal: '2024/04/30', }, 
		{ id: 202405, valor: 'Maio 2024', dataInicial: '2024/05/01', dataFinal: '2024/05/31', }, 
		{ id: 202406, valor: 'Junho 2024', dataInicial: '2024/06/01', dataFinal: '2024/06/30', }, 
		{ id: 202407, valor: 'Julho 2024', dataInicial: '2024/07/01', dataFinal: '2024/07/31', }, 
		{ id: 202408, valor: 'Agosto 2024', dataInicial: '2024/08/01', dataFinal: '2024/08/31', }, 
		{ id: 202409, valor: 'Setembro 2024', dataInicial: '2024/09/01', dataFinal: '2024/09/30', }, 
		{ id: 202410, valor: 'Outubro 2024', dataInicial: '2024/10/01', dataFinal: '2024/10/31', }, 
		{ id: 202411, valor: 'Novembro 2024', dataInicial: '2024/11/01', dataFinal: '2024/11/30', }, 
		{ id: 202412, valor: 'Dezembro 2024', dataInicial: '2024/12/01', dataFinal: '2024/12/31' },

		{ id: 202501, valor: 'Janeiro 2025', dataInicial: '2025/01/01', dataFinal: '2025/01/31', }, 
		{ id: 202502, valor: 'Fevereiro 2025', dataInicial: '2025/02/01', dataFinal: '2025/02/29', }, 
		{ id: 202503, valor: 'Março 2025', dataInicial: '2025/03/01', dataFinal: '2025/03/31', }, 
		{ id: 202504, valor: 'Abril 2025', dataInicial: '2025/04/01', dataFinal: '2025/04/30', }, 
		{ id: 202505, valor: 'Maio 2025', dataInicial: '2025/05/01', dataFinal: '2025/05/31', }, 
		{ id: 202506, valor: 'Junho 2025', dataInicial: '2025/06/01', dataFinal: '2025/06/30', }, 
		{ id: 202507, valor: 'Julho 2025', dataInicial: '2025/07/01', dataFinal: '2025/07/31', }, 
		{ id: 202508, valor: 'Agosto 2025', dataInicial: '2025/08/01', dataFinal: '2025/08/31', }, 
		{ id: 202509, valor: 'Setembro 2025', dataInicial: '2025/09/01', dataFinal: '2025/09/30', }, 
		{ id: 202510, valor: 'Outubro 2025', dataInicial: '2025/10/01', dataFinal: '2025/10/31', }, 
		{ id: 202511, valor: 'Novembro 2025', dataInicial: '2025/11/01', dataFinal: '2025/11/30', }, 
		{ id: 202512, valor: 'Dezembro 2025', dataInicial: '2025/12/01', dataFinal: '2025/12/31' },

		{ id: 202601, valor: 'Janeiro 2026', dataInicial: '2026/01/01', dataFinal: '2026/01/31', }, 
		{ id: 202602, valor: 'Fevereiro 2026', dataInicial: '2026/02/01', dataFinal: '2026/02/29', }, 
		{ id: 202603, valor: 'Março 2026', dataInicial: '2026/03/01', dataFinal: '2026/03/31', }, 
		{ id: 202604, valor: 'Abril 2026', dataInicial: '2026/04/01', dataFinal: '2026/04/30', }, 
		{ id: 202605, valor: 'Maio 2026', dataInicial: '2026/05/01', dataFinal: '2026/05/31', }, 
		{ id: 202606, valor: 'Junho 2026', dataInicial: '2026/06/01', dataFinal: '2026/06/30', }, 
		{ id: 202607, valor: 'Julho 2026', dataInicial: '2026/07/01', dataFinal: '2026/07/31', }, 
		{ id: 202608, valor: 'Agosto 2026', dataInicial: '2026/08/01', dataFinal: '2026/08/31', }, 
		{ id: 202609, valor: 'Setembro 2026', dataInicial: '2026/09/01', dataFinal: '2026/09/30', }, 
		{ id: 202610, valor: 'Outubro 2026', dataInicial: '2026/10/01', dataFinal: '2026/10/31', }, 
		{ id: 202611, valor: 'Novembro 2026', dataInicial: '2026/11/01', dataFinal: '2026/11/30', }, 
		{ id: 202612, valor: 'Dezembro 2026', dataInicial: '2026/12/01', dataFinal: '2026/12/31' },

		{ id: 202701, valor: 'Janeiro 2027', dataInicial: '2027/01/01', dataFinal: '2027/01/31', }, 
		{ id: 202702, valor: 'Fevereiro 2027', dataInicial: '2027/02/01', dataFinal: '2027/02/29', }, 
		{ id: 202703, valor: 'Março 2027', dataInicial: '2027/03/01', dataFinal: '2027/03/31', }, 
		{ id: 202704, valor: 'Abril 2027', dataInicial: '2027/04/01', dataFinal: '2027/04/30', }, 
		{ id: 202705, valor: 'Maio 2027', dataInicial: '2027/05/01', dataFinal: '2027/05/31', }, 
		{ id: 202706, valor: 'Junho 2027', dataInicial: '2027/06/01', dataFinal: '2027/06/30', }, 
		{ id: 202707, valor: 'Julho 2027', dataInicial: '2027/07/01', dataFinal: '2027/07/31', }, 
		{ id: 202708, valor: 'Agosto 2027', dataInicial: '2027/08/01', dataFinal: '2027/08/31', }, 
		{ id: 202709, valor: 'Setembro 2027', dataInicial: '2027/09/01', dataFinal: '2027/09/30', }, 
		{ id: 202710, valor: 'Outubro 2027', dataInicial: '2027/10/01', dataFinal: '2027/10/31', }, 
		{ id: 202711, valor: 'Novembro 2027', dataInicial: '2027/11/01', dataFinal: '2027/11/30', }, 
		{ id: 202712, valor: 'Dezembro 2027', dataInicial: '2027/12/01', dataFinal: '2027/12/31' }
	],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VEICULO_DESPESA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'VEICULO_DESPESA_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'VEICULO_DESPESA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'VEICULO_DESPESA_TIPO_SELECT_LISTADO':
            return {
				...state,
				listaTipo: action.payload.data
			};

		case 'VEICULO_DESPESA_ITEM_SELECT_LISTADO':
            return {
				...state,
				listaItem: action.payload.data
			};

        default:
            return state;
    }
}
