import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_CUSTO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaCustoFolha() {
    const request = axios.get(`${consts.API_URL}/custoFolhaMes/dashboard`);
    return {
        type: 'DASHBOARD_CUSTO_FOLHA_LISTADO',
        payload: request
    };
}

export function getListaCustoFrota() {
    const request = axios.get(`${consts.API_URL}/custoFrotaMes/dashboard`);
    return {
        type: 'DASHBOARD_CUSTO_FROTA_LISTADO',
        payload: request
    };
}

export function getListaCustoAbastecida() {
    const request = axios.get(`${consts.API_URL}/saida/dashboardCusto`);
    return {
        type: 'DASHBOARD_CUSTO_ABASTECIDA_LISTADO',
        payload: request
    };
}

export function getListaKmRodado() {
    const request = axios.get(`${consts.API_URL}/veiculo/listarDashboardCustoKmRodadoMes`);
    return {
        type: 'DASHBOARD_CUSTO_KM_RODADO_LISTADO',
        payload: request
    };
}

export function getListaVenda() {
    const request = axios.get(`${consts.API_URL}/vendaImportacao/listarDashboardCusto`);
    return {
        type: 'DASHBOARD_CUSTO_VENDA_LISTADO',
        payload: request
    };
}

export function salvarCustoFolha(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/custoFolhaMes`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaCustoFolha());
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/custoFolhaMes`, {
				...registro,
				valor: FormatUtils.formatarValorBanco(registro.valor, 2),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaCustoFolha());
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}